// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#accept {
  background: rgb(15, 19, 25);
  border-radius: 2rem;
  padding: 2rem;
  margin-top: -40vh !important;
  height: fit-content !important;
  resize: both; /* This allows resizing in both directions (height and width) */
  overflow: auto; /* This ensures that scrollbars appear if the content overflows */
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/contacts/accept/accept.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,2BDM0B;ECL1B,mBAAA;EACA,aAAA;EACA,4BAAA;EACA,8BAAA;EACA,YAAA,EAAA,+DAAA;EACA,cAAA,EAAA,iEAAA;AAIF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
