import { bindable } from 'aurelia-framework';
import { UsersHttpClients } from './../../../../http_clients/UsersHttpClients';
import { autoinject } from 'aurelia-framework';
import { default as env } from '../../../../../config/environment.json'
import { User } from 'components/models/UserModel';
import { Store, connectTo } from 'aurelia-store';
import { State } from 'state';
import { IGenericAccount } from 'components/models/YapilyModels/IGenericAccount';
  
@connectTo()
@autoinject()
export class Search {
  private search: string = "";
  private friend: User;
  @bindable private next;
  private parentMethod;
  private contactToAccept;
  

  constructor(private userHttpClient: UsersHttpClients, private store: Store<State>, private state: State,) {
    
  }
  
  activate(model) {
    this.parentMethod = model;
  }

  goNext() {
    this.store.dispatch("contacts.adding.search.selected", this.friend);
    this.parentMethod();
  }

  bind() {
    this.goNext();
    this.contactToAccept = this.state.selectedContactToAccept;
  }

  /**
   * A rare case where we will still use http client
   * instead of web socket, because we want only this client
   * to receive the result of the search and not the
   * others.
   */
  async searchContact() {
    let httpRequest = await this.userHttpClient.fetch("/profile/mail/" + this.search.toLowerCase)
    
    if(!httpRequest.ok)
      return;

    this.friend = await httpRequest.json();
  }
}
    