import { DialogController } from 'aurelia-dialog';
import { FriendModel } from "components/models/FriendModel";
import { bindable } from "aurelia-framework";
import { autoinject } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { State } from "state";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { FriendRequestModel } from "components/models/FriendsRequestModel";
import { EventAggregator } from "aurelia-event-aggregator";

@connectTo()
@autoinject()
export class SelectAccounts {
  private selectedAccountsToShare: IGenericAccount;
  private state: State;
  private contactToAccept;
  private countSelectedWallets: number = 0;

  constructor(private ea: EventAggregator, private dialogController: DialogController) {
  }

  activate() {}

  bind() {
    // Initialize selected accounts
    this.initializeSelectedWalletCount();
    this.contactToAccept = this.state.selectedContactToAccept;
  }

  acceptContact() {
    const selectedWallets = this.state.wallets.filter(
      (wallet) => wallet.selected
    );
    let contactBody = new FriendRequestModel();
    (contactBody._id = this.contactToAccept.request_id),
      (contactBody.myAccountsIds = selectedWallets),
      (contactBody.status = "accepted");
    this.ea.publish("contacts.patch.one.backend", contactBody);
    this.dialogController.close(true);
  }

  updateSelectedWallets(wallet: IGenericAccount) {
    !wallet.selected ? this.addOneMoreSelectedWallet(wallet) : this.removeOneSelectedWallet(wallet);
  }
  
  addOneMoreSelectedWallet(wallet: IGenericAccount) {
    wallet.selected = !wallet.selected;
    this.countSelectedWallets++;
  }

  removeOneSelectedWallet(wallet: IGenericAccount) {
    wallet.selected = !wallet.selected;
    this.countSelectedWallets--;
  }

  initializeSelectedWalletCount() {
    this.countSelectedWallets = this.state.wallets.filter(wallet => wallet.selected).length;
  }

  alreadySelected() {
    const accounts = this.state.selectedContactToAccept.accounts;

    const accountArray = Array.isArray(accounts) ? accounts : [accounts];

    const namesToSelect = [];
    for (const account of accountArray) {
      namesToSelect.push(account.name);
    }

    // Reset metric
    this.countSelectedWallets = 0;

    for (const wallet of this.state.wallets) {
      const isSelected = namesToSelect.includes(wallet.name);

      if (isSelected) {
        wallet.selected = true;
        this.countSelectedWallets++;  // Update metric
      }
    }

  }
}
