import { FriendModel } from "components/models/FriendModel";
import { bindable } from "aurelia-framework";
import { autoinject } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { State } from "state";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { FriendRequestModel } from "components/models/FriendsRequestModel";
import { EventAggregator } from "aurelia-event-aggregator";
import { DialogController } from "aurelia-dialog";
import { NotificationHttpClient } from "http_clients/NotificationHttpClient";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { Router } from "aurelia-router";

@connectTo()
@autoinject()
export class SelectAccounts {
  private selectedAccountsToShare: IGenericAccount;
  private state: State;
  private countSelectedWallets: number = 0;
  private isMe: boolean = false;

  constructor(
    private ea: EventAggregator,
    private dialogController: DialogController,
    private notificationHttpClient: NotificationHttpClient,
    private router: Router
  ) {
  }

  activate() {}

  // setting all wallets as unselected
  bind() {
    this.state.wallets.map((wallet) => (wallet.selected = false));
    if (this.state.me.email == this.state.selectedContactToAdd.email){
      this.isMe = true;
    }
  }

  async createContact() {
    const selectedWallets = this.state.wallets.filter(
      (wallet) => wallet.selected
    );

    let contactBody = new FriendRequestModel();
    (contactBody.friend_id_fk = this.state.selectedContactToAdd._id),
    (contactBody.myAccountsIds = selectedWallets),
    this.ea.publish("contacts.add.one.backend", contactBody);
    this.dialogController.close(true);
    this.router.navigateToRoute("contacts", {page: "sent"})
  }

  updateSelectedWallets(wallet: IGenericAccount) {
    !wallet.selected
      ? this.addOneMoreSelectedWallet(wallet)
      : this.removeOneSelectedWallet(wallet);
  }

  addOneMoreSelectedWallet(wallet: IGenericAccount) {
    wallet.selected = !wallet.selected;
    this.countSelectedWallets++;
  }

  removeOneSelectedWallet(wallet: IGenericAccount) {
    wallet.selected = !wallet.selected;
    this.countSelectedWallets--;
  }
}
