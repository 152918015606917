import { autoinject } from 'aurelia-dependency-injection';
import { DialogController } from 'aurelia-dialog';
import { connectTo } from 'aurelia-store';
import { State } from 'state';

@connectTo()
@autoinject()
export class Accept {
  currentStep = './search/search';
  private state: State;

  constructor(private dialogController: DialogController) {
    this.dialogController = dialogController;
    this.currentStep = "./search/search";
  }

  deactivate() {
    this.dialogController.cancel();
  }

  next() {
    this.currentStep = './select-accounts/select-accounts';
  }
}
