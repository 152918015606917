// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#selectaccounts {
  display: flex;
  flex-direction: column;
}
section#selectaccounts p.title {
  color: #F9F9F9;
  font-family: "Inter";
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0rem;
  margin-bottom: 1rem;
}
section#selectaccounts div.contacts {
  border: 1px solid var(--stroke-dark-base, #2F3340);
  border-radius: 1rem;
  flex: 3;
  padding: 1rem;
}
section#selectaccounts div.contacts div.user_info {
  align-items: center;
  display: flex;
  flex-direction: row;
}
section#selectaccounts div.contacts div.user_info img {
  aspect-ratio: 1/1;
  border-radius: 100%;
  height: 3rem;
  object-fit: cover;
  margin-right: 1rem;
  width: 3rem;
}
section#selectaccounts div.contacts div.user_info p {
  display: flex;
  flex-direction: column;
  margin: 0rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
section#selectaccounts div.contacts div.user_info p span.name {
  color: #F9F9F9;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
}
section#selectaccounts div.contacts div.user_info p span.identification {
  color: #AFB3C0;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
}
section#selectaccounts p.title_share {
  color: #F9F9F9;
  font-family: "Inter";
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
section#selectaccounts div.wallets {
  align-items: center;
  border: 1px solid var(--stroke-dark-base, #2F3340);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
section#selectaccounts div.wallets div.wallet {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
}
section#selectaccounts div.wallets div.wallet img {
  aspect-ratio: 1/1;
  border-radius: 100%;
  height: 3rem;
  object-fit: cover;
  margin-right: 1rem;
  width: 3rem;
}
section#selectaccounts div.wallets div.wallet p {
  display: flex;
  flex-direction: column;
  margin: 0rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
section#selectaccounts div.wallets div.wallet p span.name {
  color: #F9F9F9;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
}
section#selectaccounts div.wallets div.wallet p span.identification {
  color: #AFB3C0;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
}
section#selectaccounts div.wallets div.wallet.selected {
  background-color: rgba(40, 40, 40, 0.52) !important;
  border: none !important;
}
section#selectaccounts div.wallets div.wallet.selected:last-child {
  border-radius: 0rem 0rem 1rem 1rem;
}
section#selectaccounts div.wallets div.wallet:first-child {
  border-radius: 1rem 1rem 0rem 0rem;
}
section#selectaccounts button.btn-fresh-primary {
  border-radius: 1rem !important;
  margin: 1rem auto auto auto !important;
  text-align: center;
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/contacts/accept/select-accounts/select-accounts.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,aAAA;EACA,sBAAA;AAIF;AAFE;EACE,cAAA;EACA,oBDkGqB;ECjGrB,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AAIJ;AADE;EACE,kDAAA;EACA,mBAAA;EACA,OAAA;EACA,aAAA;AAGJ;AADI;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;AAGN;AADM;EACE,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAGR;AADM;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;AAGR;AADQ;EACE,cDnCuB;ECoCvB,oBD+De;EC9Df,eAAA;EACA,gBAAA;EACA,mBAAA;AAGV;AADQ;EACE,cD5CiB;EC6CjB,oBDwDe;ECvDf,eAAA;EACA,gBAAA;EACA,mBAAA;AAGV;AAGE;EACE,cDrD6B;ECsD7B,oBD6CqB;EC5CrB,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAIE;EACE,mBAAA;EACA,kDAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;AAFJ;AAII;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;AAFN;AAIM;EACE,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAFR;AAIM;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;AAFR;AAIQ;EACE,cD9FuB;EC+FvB,oBDIe;ECHf,eAAA;EACA,gBAAA;EACA,mBAAA;AAFV;AAIQ;EACE,cDvGiB;ECwGjB,oBDHe;ECIf,eAAA;EACA,gBAAA;EACA,mBAAA;AAFV;AAMI;EACE,mDAAA;EACA,uBAAA;AAJN;AAMI;EACE,kCAAA;AAJN;AAMI;EACE,kCAAA;AAJN;AAQE;EACE,8BAAA;EACA,sCAAA;EACA,kBAAA;EACA,sBAAA;AANJ","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
