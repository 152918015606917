import { bindable } from 'aurelia-framework';
import { UsersHttpClients } from './../../../../http_clients/UsersHttpClients';
import { autoinject } from 'aurelia-framework';
import { default as env } from '../../../../../config/environment.json'
import { User } from 'components/models/UserModel';
import { Store, connectTo } from 'aurelia-store';
import { IGenericAccount } from 'components/models/YapilyModels/IGenericAccount';
import { PaymailWalletClient } from 'http_clients/PaymailWalletClient';
import { getPublicProfile } from 'services/paymailClient';
import { WalletModel } from 'services/models/WalletModel';
import * as IBAN from "iban";
import { Router } from 'aurelia-router';
import { DialogController } from 'aurelia-dialog';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { RecipientModel } from 'components/models/RecipientModel';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';
import { State } from 'state';
  
  @connectTo()
  @autoinject()
  export class Search {
    private search: string = "";
    private friend: User;
    private recipient: RecipientModel = new RecipientModel;
    private friendFound: boolean;
    private isUk: boolean = false;
    private isBlockchain: boolean = false;
    private isInviting: boolean = false;
    private displayError: boolean = false;
    private errorMessage: string = "";
    private loader: boolean;
    @bindable private next;
    @bindable private friendEmail: string;
    private parentMethod;
    

    constructor(private userHttpClient: UsersHttpClients, private store: Store<State>, private state: State,
      private paymailWalletHttpClient: PaymailWalletClient, private router: Router, 
      private dialogController: DialogController, private ea: EventAggregator, private i18n: I18N) {

    }
    
    activate(model) {
      this.parentMethod = model;      
    }

    goNext() {
      this.store.dispatch("contacts.adding.search.selected", this.friend);
      this.parentMethod();
    }

    async bind() {
      if (this.friendEmail) {
        this.searchContact(this.friendEmail);
      }
    }
    /**
     * A rare case where we will still use http client
     * instead of web socket, because we want only this client
     * to receive the result of the search and not the
     * others.
     * @todo if 404 
     * try with paymail wallet - done
     */
    async searchContact(search: string) {
      this.loader = true;

      let alreadyAContact = this.state.contacts.filter(contact => contact.email === search);
      if (alreadyAContact.length > 0){
        this.displayError = true;
        this.loader = false;
        this.errorMessage = this.i18n.tr("error.add.already_added");
        setTimeout(() => {
          this.displayError = false;
          this.loader = false;
        }, 3000);
      }
      else {
        //FILTER user mail & paymail MyBanka
        
        if (search.includes("@") && search.includes(".")) {
          this.checkIfUser(search);
        } else {
          this.checkIfUK(search);
        }
      }
    }

    async checkIfUser(search) {
      this.loader = true;
      this.friend = undefined;
      let httpRequest = await this.userHttpClient.fetch("/profile/mail/" + search)
      if(httpRequest.ok) {
        this.friend = await httpRequest.json();
        this.loader = false;
      }
      else {
        this.checkIfPaymail(search);
      }
    }

    async checkIfPaymail(search) {
      this.loader = true;

      try {
        const paymailRecipient = await getPublicProfile(search);
        if (paymailRecipient) {
          this.recipient.isBlockchain = true;
          this.recipient.isUk = false;
          this.recipient.name = paymailRecipient.name || "Paymail";
          this.recipient.urlPic = paymailRecipient.avatar;
          this.recipient.iban = search;
          this.loader = false;
          this.friendFound = true;
        }
        else {
          this.isInviting = true;
          this.loader = false;
        } 
      }
      catch(e) {
        console.log(e);
        this.isInviting = true;
      }
    }

    checkIfUK(search) {
      this.loader = true;

      if (search.length == 8 && (/^[0-9]+$/.test(search))) {
        this.isUk = true;
        this.isBlockchain = false;
        this.recipient.name = "Account number";
        this.recipient.urlPic = "/img/bank_account_white.png";
        this.recipient.iban = search;
        this.loader = false;
        this.friendFound = true;
      }
      else {
        this.loader = false;
        this.checkIfIban(search);
      }

    }

    checkIfIban(search) {
      this.loader = true;
      if (!IBAN.isValid(search)) {
        this.displayError = true
        this.loader = false;
        this.errorMessage = this.i18n.tr("error.add.no_search_result");
      }
      else {
        this.displayError = false;
        this.isBlockchain = false;
        this.isUk = false;
        this.recipient.name = "IBAN"
        this.recipient.urlPic = "/img/bank_account_white.png";
        this.recipient.iban = search;
        this.loader = false;
        this.friendFound = true;
      }
    }

    createRecipient(search) {
      if (this.recipient.isBlockchain) {
        this.dialogController.ok();
        this.ea.publish("recipient.add.one.backend", this.recipient);
      }
      else {
        this.goToRecipients(search, this.isUk, this.isBlockchain)
      }
    }

    inviteFriend() {
      //Call to invite friend
    }

    goToRecipients(search, isUk?, isBlockchain?) {
      this.dialogController.ok();
      this.router.navigateToRoute("create_recipients", {search, isUk, isBlockchain})
    }

    goToAddAccount() {
      this.dialogController.ok();
      this.router.navigateToRoute('choose_type_account');
    }
  }
    