// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/info.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/svg/search.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/user-circle.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./search.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>
  <section id="search">
    <p class="title" t="contacts.search_title">
    </p>
    <div class="search_result" if.bind="state.wallets.length < 1" click.delegate="goToAddAccount()">
      <div class="contacts">
        <div class="user_info" >
          <img src="${___HTML_LOADER_IMPORT_0___}">
            <p>
              <span class="identification" i18n="contacts.need_account_to_add_contact"></span>
            </p>
        </div>
      </div>
    </div>
    <div class="spacer-16"></div>
    <div class="search_section flex-column">
      <div class="form_fresh" if.bind="state.wallets.length > 0">
        <form submit.delegate="searchContact(search)">
          <img class="search_img" if.bind="!loader" src="${___HTML_LOADER_IMPORT_1___}" click.delegate="searchContact(search)">
          <loader-ring if.bind="loader"></loader-ring>
          <input id="search-input-2" value.bind="search" t="[placeholder]contacts.recherche_add">
        </form>
      </div>
      <button class="btn btn-primary hidden_on_desktop" click.delegate="searchContact(search)">
        <span t="home.search"></span>
      </button>
    </div>
    <div class="search_result" if.bind="friend">
      <div class="contacts" click.delegate="goNext()">
        <div class="user_info" >
          <img src.bind="friend.urlPic">
            <p>
              <span class="name">\${friend.displayName}</span>
              <span class="identification">\${friend.email}</span>
            </p>
        </div>
      </div>
    </div>
    <div class="search_result" if.bind="friendFound && !friend && !displayError">
      <div class="contacts">
        <div class="user_info" >
          <img src.bind="recipient.urlPic">
            <p>
              <span class="name">\${recipient.name}</span>
              <span class="identification">\${recipient.iban}</span>
            </p>
        </div>
      </div>
      <button class="btn btn-primary" click.delegate="createRecipient(search)">
        <span t="contacts.new_recipient">Create a new recipient</span>
      </button>
    </div>
    <div class="search_result" if.bind="isInviting && !friend && !displayError">
      <div class="contacts">
        <div class="user_info" >
          <img src="${___HTML_LOADER_IMPORT_2___}">
            <p>
              <span class="name">\${search}</span>
            </p>
        </div>
      </div>
      <button class="btn btn-primary" click.delegate="inviteFriend()">
        <span t="contacts.invite_friend">Invite a contact or a friend</span>
      </button>
    </div>
    <div class="search_result" if.bind="displayError">
      <div class="contacts">
        <div class="user_info" >
          <img src="${___HTML_LOADER_IMPORT_0___}">
            <p>
              <span class="identification">\${errorMessage}</span>
            </p>
        </div>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;