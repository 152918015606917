// Module
var code = `<template>
  <require from="./search.scss"></require>
  <section id="search">
    <p class="title" t="contacts.search_title">
    </p>
    <div class="search_result">
      <div class="contacts" click.delegate="goNext()">
        <div class="user_info">
          <img src.bind="state.contactToAccept.urlPic">
            <p>
              <span class="name">\${state.contactToAccept.name}</span>
              <span class="identification">\${state.contactToAccept.email}</span>
            </p>
        </div>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;