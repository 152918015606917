// Module
var code = `<template>
  <require from="./select-accounts.scss"></require>
  <section id="selectaccounts">
    <p class="title" t="contacts.contacts_choose_account.share_account_contacts">
    </p>
    <div class="contacts">
      <div class="user_info">
        <img src.bind="state.selectedContactToAdd.urlPic">
          <p>
            <span class="name">\${state.selectedContactToAdd.displayName}</span>
            <span class="identification">\${state.selectedContactToAdd.email}</span>
          </p>
      </div>
    </div>

    <p class="title_share" t="contacts.contacts_choose_account.add_account_contacts1">
    </p>

    <div class="wallets">
      <div class="wallet" repeat.for="wallet of state.wallets" 
        click.delegate="updateSelectedWallets(wallet)"
        class.bind="wallet.selected ? 'selected' : '' "
        if.bind="!wallet.isError">
        <img src.bind="wallet.picture">
          <p>
            <span class="name">\${wallet.name}</span>
            <span class="identification">\${wallet.identification}</span>
          </p>
      </div>
    </div>
    <button class="btn-fresh btn-fresh-primary" t="contacts.contacts_choose_Account_button" 
      click.delegate="createContact()"
      disabled.bind="countSelectedWallets < 1 || isMe"></button>
  </section>
</template>
`;
// Exports
export default code;