// Module
var code = `<template>
  <require from="./accept.scss"></require>
  <require from="./search/search"></require>
  <require from="./select-accounts/select-accounts"></require>

  <section id="accept">
    <compose view-model.bind="currentStep" model.call="next()"></compose>
  </section>
  
</template>
 `;
// Exports
export default code;