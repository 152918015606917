// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#search p {
  color: #F9F9F9;
  font-family: "Inter";
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0rem;
  margin-bottom: 1rem;
}
section#search div.form_fresh {
  border: 1px solid var(--stroke-dark-base, #2F3340);
  border-radius: 1rem;
  padding: 1rem;
}
section#search div.form_fresh form {
  align-items: center;
  display: flex;
  flex-direction: row;
}
section#search div.form_fresh form img {
  aspect-ratio: 1/1;
  height: 1.5rem;
  margin-right: 1rem;
  object-fit: cover;
  width: 1.5rem;
}
section#search div.form_fresh form input {
  color: #F9F9F9;
  font-family: "Inter";
  font-weight: 500;
  font-size: 1rem;
  text-align: start;
}
section#search div.search_result {
  margin-top: 1rem;
}
section#search div.search_result div.contacts {
  border: 1px solid var(--stroke-dark-base, #2F3340);
  border-radius: 1rem;
  flex: 3;
  padding: 1rem;
}
section#search div.search_result div.contacts div.user_info {
  align-items: center;
  display: flex;
  flex-direction: row;
}
section#search div.search_result div.contacts div.user_info img {
  aspect-ratio: 1/1;
  border-radius: 100%;
  height: 3rem;
  object-fit: cover;
  margin-right: 1rem;
  width: 3rem;
}
section#search div.search_result div.contacts div.user_info p {
  display: flex;
  flex-direction: column;
  margin: 0rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
section#search div.search_result div.contacts div.user_info p span.name {
  color: #F9F9F9;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
}
section#search div.search_result div.contacts div.user_info p span.identification {
  color: #AFB3C0;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/contacts/accept/search/search.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACGE;EACE,cDG6B;ECF7B,oBDqGqB;ECpGrB,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AAGJ;AAAE;EACE,kDAAA;EACA,mBAAA;EACA,aAAA;AAEJ;AAAI;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;AAEN;AAAM;EACE,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,aAAA;AAER;AACM;EACE,cDxByB;ECyBzB,oBD0EiB;ECzEjB,gBAAA;EACA,eAAA;EACA,iBAAA;AACR;AAKE;EACE,gBAAA;AAHJ;AAKI;EACE,kDAAA;EACA,mBAAA;EACA,OAAA;EACA,aAAA;AAHN;AAKM;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;AAHR;AAKQ;EACE,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAHV;AAKQ;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;AAHV;AAKU;EACE,cDhEqB;ECiErB,oBDkCa;ECjCb,eAAA;EACA,gBAAA;EACA,mBAAA;AAHZ;AAKU;EACE,cDzEe;EC0Ef,oBD2Ba;EC1Bb,eAAA;EACA,gBAAA;EACA,mBAAA;AAHZ","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
