// Module
var code = `<template>
  <require from="./add.scss"></require>
  <require from="./search/search"></require>
  <require from="./select-accounts/select-accounts"></require>

  <section id="add">
    <compose view-model.bind="currentStep" model.call="next()"></compose>
  </section>
  
</template>
 `;
// Exports
export default code;